import React from 'react';
import Contact from './Contact';
import {Routes, NavLink, Route} from "react-router-dom";

const Footer = () => {
  const current = new Date();
  const CurrentYear = `${current.getFullYear()}`;
  
  return (
    <div> 
      <footer className="bg-blur bg-primary">
        <div className="mt-4">
          <div className="container">
            <div className="row">
              <div className="col-md-4 col-sm-6">
                <h5 className="text-dark">Email <i className="fa fa-envelope" aria-hidden="true"></i></h5>
                <p className="paragraph-lg font-secondary"><h5 className="text-dark"><b>s.v.takawale@gmail.com</b></h5></p>
              </div>
              <div className="col-md-3 col-sm-6">
                <h5 className="text-dark">Phone <i className="fa fa-mobile fa-lg" aria-hidden="true"></i></h5>
                <p className=" paragraph-lg font-secondary"><h5 className="text-dark"><b>9850265102</b></h5></p>
              </div>
              <div className="col-md-2">
                <div className="navbar navbar-expand-lg">
                  <ul className="d-flex justify-content-between zindex-1 text-dark">
                      <li className="mb-3 mx-3"><a target="_blank" rel="noreferrer" className="text-dark" href="https://www.dribbble.com/svtakawale"><i className="fab fa-dribbble  fa-2x"></i></a></li>
                      <li className="mb-3 mx-3"><a target="_blank" rel="noreferrer" className="text-dark" href="https://www.behance.net/svtakawale"><i className="fab fa-behance  fa-2x"></i></a></li>
                      <li className="mb-3 mx-3"><a target="_blank" rel="noreferrer" className="text-dark" href="https://www.linkedin.com/in/swapnil-takawale-bb994b54/"><i className="fab fa-linkedin  fa-2x"></i></a></li>
                      <li className="mb-3 mx-3"><a target="_blank" rel="noreferrer" className="text-dark" href="https://github.com/Swapnil9659"><i className="fab fa-github-square  fa-2x"></i></a></li>
                  </ul>
                </div>
              </div>
              <NavLink className="my-3" to="/contact"><span className="text-dark text-uppercase hover-shadow font-rounded fs-5 btn btn-primary shadow-lg rounded-4 fw-light px-3"><i class="far fa-envelope fw-light pe-2 fa-1x"></i>Let's Connect</span></NavLink>
              <div className=" rounded ">
                <Routes>
                  <Route  path="/contact" element={ <Contact />} />
                </Routes>
              </div>
            </div>
          </div>
        </div>
        <div className="border-top text-center border-warning py-3 App">
          <p className="mb-0"><h5 className="text-dark">&#169; TCOM<br/>Year {CurrentYear} Design and Developed by <b className="text-dark font-Croissant">s.v.takawale</b></h5></p>
        </div>     
      </footer>
    </div>
)
}

export default Footer;