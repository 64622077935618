import './App.css';
import './Post.css';
import './Code.css';
//import { UserContextProvider }   from "./Context/UserContext";
import { BrowserRouter , Route, Routes} from "react-router-dom";
import Home from './Component/Home';
import About from './Component/About';
import Blogs from './Component/Blogs';
import Blog from './Component/Blog';
import Portfolio from './Component/Portfolio';
import Contact from './Component/Contact';
import Blog1 from './Component/Blog1';
import SBlog1 from './Component/SBlog1';
import SBlog2 from './Component/SBlog2';
import SBlog3 from './Component/SBlog3';
import SBlog4 from './Component/SBlog4';
import SBlog5 from './Component/SBlog5';
import SBlog6 from './Component/SBlog6';
import SBlog7 from './Component/SBlog7';
import SBlog8 from './Component/SBlog8';
import SBlog9 from './Component/SBlog9';
import SBlog10 from './Component/SBlog10';
import SBlog11 from './Component/JavaScript/SBlog11';
import JS1 from'./Component/JavaScript/JS1';
import JS2 from'./Component/JavaScript/JS2';
import SBlog12 from './Component/ReactJS/SBlog12';
import RJS1 from './Component/ReactJS/RJS1';
import RJS2 from './Component/ReactJS/RJS2';
import RJS3 from './Component/ReactJS/RJS3';
import RJS4 from './Component/ReactJS/RJS4';
import RJS5 from './Component/ReactJS/RJS5';
import RJS6 from './Component/ReactJS/RJS6';
import RJS7 from './Component/ReactJS/RJS7';
import RJS8 from './Component/ReactJS/RJS8';
import RX1 from './Component/Redux/RX1';
import DP1 from './Component/DP1';
import DP2 from './Component/DP2';
import ShoppingCart from './Component/ShoppingCart';
import Ftbee from './Component/Ftbee';
import HospitalPlus from './Component/HospitalPlus';
import NutratrainX from './Component/NutratrainX';

function App() {
  return (
    <div>
      <BrowserRouter>
          <Routes>
            <Route  path="/home" element={ <Home />} />
            <Route  path="/about" element={ <About /> } />
            <Route  path="/blogs" element= { <Blogs />} />
            <Route  path="/blog" element= { <Blog />} />
            <Route  path="/portfolio" element= { <Portfolio  /> } />
            <Route  path="/contact" element={ <Contact />} />
            <Route  path="/blog1" element= { <Blog1 />} />
            <Route  path="/sblog1" element={ <SBlog1 />} />
            <Route  path="/sblog2" element={ <SBlog2 />} />
            <Route  path="/sblog3" element={ <SBlog3 />} />
            <Route  path="/sblog4" element={ <SBlog4 />} />
            <Route  path="/sblog5" element={ <SBlog5 />} />
            <Route  path="/sblog6" element={ <SBlog6 />} />
            <Route  path="/sblog7" element={ <SBlog7 />} />
            <Route  path="/sblog8" element={ <SBlog8 />} />
            <Route  path="/sblog9" element={ <SBlog9 />} />
            <Route  path="/sblog10" element={ <SBlog10 />} />
            <Route  path="/sblog11" element={ <SBlog11 />} />
            <Route  path="/js1" element={ <JS1 />} />
            <Route  path="/js2" element={ <JS2 />} />
            <Route  path="/sblog12" element={ <SBlog12 />} />
            <Route  path="/rjs1" element={ <RJS1/>} />
            <Route  path="/rjs2" element={ <RJS2/>} />
            <Route  path="/rjs3" element={ <RJS3/>} />
            <Route  path="/rjs4" element={ <RJS4/>} />
            <Route  path="/rjs5" element={ <RJS5/>} />
            <Route  path="/rjs6" element={ <RJS6/>} />
            <Route  path="/rjs7" element={ <RJS7/>} />
            <Route  path="/rjs8" element={ <RJS8/>} />
            <Route  path="/rx1" element={ <RX1/>} />
            <Route  path="/dp1" element={ <DP1 />} />
            <Route  path="/dp2" element={ <DP2 />} />
            <Route  path="/shoppingcart" element={ <ShoppingCart />} />
            <Route  path="/ftbee" element={ <Ftbee />} />
            <Route  path="/hospitalplus" element={ <HospitalPlus />} />
            <Route  path="/nutratrainX" element={ <NutratrainX />} />
            <Route  exact path="/*" element={ <Home />} />
          </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
