import React from "react";
import Nav from 'react-bootstrap/Nav';
import {Routes, NavLink, Route} from "react-router-dom";
import Carouselslider from "./Carouselslider";
import Contact from './Contact'
import Hfooter from "./Hfooter";
import './home.css';
import BackToTopBtn from './BackToTopBtn';
import TestimonialSlider from "./TestimonialSlider";
import Header from "./Header";
const Home = () => {
 
  return (
    <div>
      <Header/>
      <section className=" bg-gradient" id="parallax">
        <div className="navbar navbar-expand-sm mb-3 py-2 ms-2 ps-4">
            <ul className="d-flex justify-content-between zindex-1 text-dark">
              <li className="py-2 text-primary fw-bold me-3 fs-4 font-Croissant">Check My</li>
              <li className="border rounded p-1 shadow-lg"><a target="_blank" rel="noreferrer" className="text-black" href="https://www.behance.net/svtakawale"><i className="fab fa-behance-square text-primary fa-2x"></i></a></li>
              <li className="mx-2 border rounded p-1 shadow-lg"><a target="_blank" rel="noreferrer" className="text-black" href="https://www.linkedin.com/in/swapnil-takawale-bb994b54/"><i className="fab fa-linkedin text-primary fa-2x"></i></a></li>
              <li className="border rounded p-1 shadow-lg"><a target="_blank" rel="noreferrer" className="text-black" href="https://github.com/Swapnil9659"><i className="fab fa-github-square text-primary fa-2x"></i></a></li>
            </ul>
        </div>
        <div className="bg-blur min-vh-100 pb-1 mb-3">
          <div className="col-lg-12 col-12 shuffle-item" data-groups="[&quot;design&quot;,&quot;illustration&quot;]">
              <Carouselslider className="bg-primary" />
          </div>
          <Nav className="shadow-lg bg-primary">
            <NavLink className="mx-auto"><label className="text-light text-uppercase font-rounded fs-3 px-3 mt-1 fw-normal">Work Highlights</label></NavLink>
          </Nav>
          <TestimonialSlider/>
          <Nav className="shadow-lg bg-primary">
            <NavLink className="mx-auto" to="/contact"><span className="text-dark text-uppercase hover-shadow-lg font-rounded btn btn-primary fs-5 shadow-lg-lg rounded-3 px-3 my-1 fw-normal"><i class="far fa-envelope fw-light fa-1x pe-2"></i>Let's Connect</span></NavLink>
          </Nav>
          <div className=" ">
            <Routes>
              <Route  path="/contact" element={ <Contact />} />
            </Routes>
          </div>
        </div>
      </section>
      <BackToTopBtn/>
      <Hfooter/>
    </div>
  )
}

export default Home
