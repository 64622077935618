import React, {useState, useEffect} from "react";
import Nav from 'react-bootstrap/Nav';
import {  Routes, NavLink, Route} from "react-router-dom";
import Markdown from "markdown-to-jsx";	
import Header from '../Header';
import Footer from '../Footer';
import Code from "../Code";
import RJS4 from './RJS4';
import RJS6 from './RJS6';
import Sidebar from "../Sidebar";
import BackToTopBtn from "../BackToTopBtn";

const RJS5= () => {
    const [postContent0, setPostcontent0] = useState('');
    const [postContent1, setPostcontent1] = useState('');
    const [postContent2, setPostcontent2] = useState('');
    const [postContent3, setPostcontent3] = useState('');
    const [postContent4, setPostcontent4] = useState('');
    const [isDark, setIsDark] = useState(true);

    useEffect(() => {
       
        import('./../ReactJS-Tutorials/Tutorial13.md')
        .then(res =>
          fetch(res.default)
            .then(response => response.text())
            .then(response => setPostcontent0(response))
            .catch(err => console.log(err))
        )
        import('./../ReactJS-Tutorials/Tutorial14.md')
        .then(res =>
          fetch(res.default)
            .then(response => response.text())
            .then(response => setPostcontent1(response))
            .catch(err => console.log(err))
        )
        import('./../ReactJS-Tutorials/Tutorial15.md')
        .then(res =>
          fetch(res.default)
            .then(response => response.text())
            .then(response => setPostcontent2(response))
            .catch(err => console.log(err))
        )
        import('./../ReactJS-Tutorials/Tutorial16.md')
        .then(res =>
          fetch(res.default)
            .then(response => response.text())
            .then(response => setPostcontent3(response))
            .catch(err => console.log(err))
        )
        import('./../ReactJS-Tutorials/Tutorial17.md')
        .then(res =>
          fetch(res.default)
            .then(response => response.text())
            .then(response => setPostcontent4(response))
            .catch(err => console.log(err))
        )
      }, [])
   
   
  return (
    <div className="overflow-hidden">
        <Header/>

        <section className="bg-primary position-relative">
            <div className="container">
                <div className="row">
                <div className="col-12 text-center">
                    <h3 className="text-white font-tertiary pt-2">ReactJS</h3>
                </div>
                </div>
            </div>
        </section>
        <section className="section mb-200">
            <div className="bg-blur rounded">
                <div className="row p-3">
                    <div className="col-lg-12">
                        
                        <p className="text-dark px-4">Published by <span className="text-dark font-Croissant">tcom</span></p>
                        <div className="content rounded">
                            <embed src="images/blog/ReactJS.png" alt="post-thumb" className="img-fluid rounded float-center mr-5 mb-4 w-100" />
                            <div>
                                <Sidebar/>
                            </div>
                            <h4 className="text-dark pt-5">7. useDebugValue Hook</h4>
                            <li>React <b>useDebugValue</b> Hook is introduced for the ReactJs versions above 18. React useDebugValue Hook helps developers to debug custom hooks.</li>
                            <li>This is incredibly useful if you are creating a library for others to use since they can easily see information about your hook, but it also is useful for your own hooks since you can quickly see detailed information about your hooks.</li>
                            <li>This debug information is displayed within the React dev tools. </li>
                            <li>These tools are part of an extension you can download in any major browser and then access as a tab within the dev tools of your browser just the same as you can access the console/network information.</li>
                            <div className="border border-dark bg-blur p-4 rounded text-dark">
                                <b>
                                export default function useUser() &#65371;<br/>
                                const [user, setUser] = useState(getUser())<br/>
                                <br/>
                                useDebugValue(user == null ? 'No User' : user.name)<br/>
                                <br/>
                                return [user, setUser]<br/>
                                &#65373;
                                </b>
                            </div>

                            <p>In this custom useUser hook we have a useDebugValue hook that takes a string as its only argument.<br/> 
                            Passing a single string to useDebugValue is by far the most common way to use this hook and when you do you will see something interesting in the React dev tools if you click on a component that utilizes this hook.<br/> 
                            On the right side of the screen you will see a section labeled hooks and inside the section you will see the User hook and next to that you will see the debug information we passed to useDebugValue.</p>
                            <embed src="images/blog/user.jpg" alt="post-thumb" className="img-fluid rounded float-center mr-5 mb-4 w-75" />

                            <h4 className="text-dark">Advanced Usage</h4>
                            <p>Passing a label to useDebugValue is by far the most common way to use this hook, but you can actually pass whatever you want to the hook and even use multiple useDebugValue hooks.</p>
                            
                            <h4 className="text-dark">Using Non-String Parameters</h4>
                            <div className="border border-dark bg-blur p-4 rounded text-dark">
                                <b>
                                export default function useUser() &#65371;<br/>
                                const [user, setUser] = useState(getUser())<br/>
                                <br/>
                                useDebugValue(user)<br/>
                                <br/>
                                return [user, setUser]<br/>
                                &#65373;
                                </b>
                            </div>
                            <embed src="images/blog/object-open.png" alt="post-thumb" className="img-fluid rounded float-center mr-5 mb-4 w-75" />
                            <p>When the hook is closed it will show a stringified label of the parameter if possible, but when you expand the hook for details you will see a <b>DebugValue</b> section that you can expand for more details on the object.</p>
                        
                            <h4 className="text-dark">Using Multiple useDebugValue Statements</h4>
                            <div className="border border-dark p-4 bg-blur rounded text-dark">
                                <b>
                                useDebugValue("#1")<br/>
                                useDebugValue("#2")
                                </b>
                            </div>
                            <embed src="images/blog/multiple-open.png" alt="post-thumb" className="img-fluid rounded float-center mr-5 mb-4 w-75" />
                            <p>As you can see this works pretty much exactly the same as if you had just passed an array to <b>useDebugValue</b>.<br/> 
                            Internally, React just combines all the <b>useDebugValue</b> hooks into one array in the debug tools.</p>
                            <div className="border border-dark bg-blur p-4 rounded text-dark">
                                <b>
                                useDebugValue(user, user =&#65310; user.getNameSlowly())
                                </b>
                            </div>

                            <p>In the above code we pass the user to <b>useDebugValue</b> as the first parameter and then as a second parameter we pass a function.<br/> 
                            This function will always take whatever you pass as the first parameter to <b>useDebugValue</b> as its argument and then whatever that function returns is displayed as the debug value label.</p>
                            <p>This means that the code to calculate the label is never actually run until the moment someone tries to inspect the hook with their dev tools.<br/> 
                            This is perfect since it will have no performance impact on your site, but can still provide valuable debug information.</p>

                            <h4 className="text-dark">8. useRef Hook</h4>
                            <p>The <b>useRef</b> hook is the new addition in React 16.8. Before proceeding to this article there is a prerequisite to know about the ref in react.<br/>
                            The <b>useRef</b> is a hook that allows to directly create a reference to the DOM element in the functional component.</p>
                            <div className="border border-dark bg-blur p-4 rounded text-dark">
                                <b>
                                Syntax:<br/>
                                const refContainer = useRef(initialValue);
                                </b>
                            </div>
                            <li>The useRef returns a mutable ref object. This object has a property called .current</li>
                            <li>The value is persisted in the refContainer.current property.</li>
                            <li>These values are accessed from the current property of the returned object.</li>
                            <li>The .current property could be initialised to the passed argument initialValue<br/> 
                            e.g. useRef(initialValue). The object can persist a value for a full lifetime of the component. </li>
                            <div className="p-2 rounded">
                                <article>
                                    <header>
                                    </header>
                                    <main> 
                                    <Markdown
                                        options={{
                                        overrides: {
                                            Code: {
                                            component: Code,
                                            props: {
                                                isDark,
                                                setIsDark
                                            }
                                            }
                                        }
                                        }}
                                    >
                                        {postContent0}
                                    </Markdown>
                                    </main>
                                </article>
                            </div>

                            <h4 className="text-dark">9. useLayoutEffect Hook</h4>
                            <p>React’s useLayoutEffect hook is almost identical to the useEffect hook.<br/>
                            A function called effect and an array of dependencies are the first and second arguments, respectively, for the useLayoutEffect hook. </p>
                            <p>After all DOM mutations have been completed by React, useLayoutEffect executes synchronously. </p>
                            <p>The useLayoutEffect hook’s syntax is almost identical to that of the useEffect hook. The effect, the first argument, has two possible outcomes: cleanup function or undefined.</p>

                            <h4 className="text-dark">Understanding useLayoutEffect with Examples</h4>
                            <div className="p-2 rounded">
                                <article>
                                    <header>
                                    </header>
                                    <main> 
                                    <Markdown
                                        options={{
                                        overrides: {
                                            Code: {
                                            component: Code,
                                            props: {
                                                isDark,
                                                setIsDark
                                            }
                                            }
                                        }
                                        }}
                                    >
                                        {postContent1}
                                    </Markdown>
                                    </main>
                                </article>
                            </div>

                            <h4 className="text-dark">useLayoutEffect VS useEffect hook</h4>
                            <p>The function passed to <b>useEffect</b> fires after layout and paint. i.e after the render has been committed to the screen.<br/>
                            This is okay for most side effects that should NOT block the browser from updating the screen.</p>
                            <p>There are cases where you may not want the behaviour <b>useEffect provides</b>. e.g. if you need to make a visual change to the DOM as a side effect.<br/> 
                            To prevent the user from seeing flickers of changes, you may use <b>useLayoutEffect</b>.</p>

                            <h4 className="text-dark">10. useTransition Hook</h4>
                            <li><b>useTransition()</b> can be used to tell React that certain state updates have a lower priority (i.e., all other state updates or UI rendering triggers have a higher priority).</li>
                            <li>When calling <b>seTransition()</b>, you get back an array with exactly two elements: An <b>isPending</b> boolean value, telling you whether the low-priority state update is still pending, and a <b>startTransition()</b> function that can be wrapped around a state update to tell React, that it is a low-priority update.</li>
                            <li><b>useTransition()</b> gives you full control since you decide which code should be wrapped and treated as "low priority". </li>
                            <div className="p-2 rounded">
                                <article>
                                    <header>
                                    </header>
                                    <main> 
                                    <Markdown
                                        options={{
                                        overrides: {
                                            Code: {
                                            component: Code,
                                            props: {
                                                isDark,
                                                setIsDark
                                            }
                                            }
                                        }
                                        }}
                                    >
                                        {postContent2 }
                                    </Markdown>
                                    </main>
                                </article>
                            </div>

                            <h4 className="text-dark">11. useDeferredValue Hook</h4>
                            <li>We use the <b>useDeferredValue</b> hook to tell React that a received prop value will trigger a transition update.</li>
                            <div className="p-2 rounded">
                                <article>
                                    <header>
                                    </header>
                                    <main> 
                                    <Markdown
                                        options={{
                                        overrides: {
                                            Code: {
                                            component: Code,
                                            props: {
                                                isDark,
                                                setIsDark
                                            }
                                            }
                                        }
                                        }}
                                    >
                                        {postContent3}
                                    </Markdown>
                                    </main>
                                </article>
                            </div>
                            
                            <h4 className="text-dark">When Should You Use Which?</h4>
                            <p>As mentioned above, the difference is that <b>useTransition()</b> wraps the state updating code,<br/> 
                            whilst <b>useDeferredValue()</b> wraps a value that's affected by the state update</p>
                            <p>You don't need to (and shouldn't) use both together, since they achieve the same goal in the end.</p>
                            <p>Instead, it makes sense to prefer <b>useTransition()</b>, if you have some state update that should be treated with a lower priority and you have access to the state updating code.<br/> 
                            If you don't have that access, use <b>seDeferredValue()</b>.</p>

                            <h4 className="text-dark">Don't Overdo It!</h4>
                            <p><b>Very important:</b> You should not start wrapping all your state updates with <b>useTransition()</b> or all your values with <b>useDeferredValue()</b>.<br/> 
                            Those hooks should be used if you have a complex user interface or component that can't be optimized with other means.<br/> 
                            You should always keep other performance improvements like lazy loading, pagination or performing work in worker threads or on the backend in mind.</p>
                            
                            <h4 className="text-dark">12. useId Hook</h4>
                            <p>The <b>useId</b> hook was formerly known as the <b>useOpaqueIdentifier</b> hook. The <b>useOpaqueIdentifier</b> was the first version of the <b>useId</b> hook, but there were many concerns when using it.<br/> 
                            After much work from the React team, it was renamed to the <b>useId hook</b>.</p>
                            <p>The <b>useId</b> hook generates a uniquely stable id that can be used across any application’s server and client side of any application.<br/> 
                            The ids generated by the <b>useId</b> hook are stable across the server and client sides.</p>
                            <p>The ids also take care of accessibility issues, meaning that accessibility APIs can use these ids to link components together. Hydration issues are also taken care of with the use of this hook.</p>

                            <h4 className="text-dark">How to use the useId hook</h4>
                            <div className="p-2 rounded">
                                <article>
                                    <header>
                                    </header>
                                    <main> 
                                    <Markdown
                                        options={{
                                        overrides: {
                                            Code: {
                                            component: Code,
                                            props: {
                                                isDark,
                                                setIsDark
                                            }
                                            }
                                        }
                                        }}
                                    >
                                        {postContent4}
                                    </Markdown>
                                    </main>
                                </article>
                            </div>

                            <h4 className="text-dark">When to use the useId hook and when not to</h4>
                            <p><b>You should use the useId hook when:</b></p>   
                            <li>You want to generate unique Ids</li>       
                            <li>You want to connect HTML elements like a label and an input.</li>  
                            <li>You are using functional components</li>

                            <p><b>You should not use the useId hook when:</b></p>   
                            <li>You need keys after mapping over a list. Use your data</li>       
                            <li>You need to target CSS selectors. This is because the useId hook returns a string that includes colons.<br/> 
                            CSS selectors do not support the strings generated from the useId hook.</li>  
                            <li>You are working with immutable values</li>

                        <div className=" pagination w-100">
                            <Routes>
                                <Route  path="/rjs4" element={ <RJS4/>} />
                                <Route  path="/rjs6" element={ <RJS6 />} />
                            </Routes>
                        </div>  
                        <Nav aria-label="Page navigation example">
                            <NavLink className="page-link text-dark rounded btn-primary p-2 mb-5 mx-4"  to="/rjs4"><b>Previous</b></NavLink>
                            <NavLink className="page-link text-dark rounded btn-primary p-2 mb-5"  to="/rjs6"><b>Next</b></NavLink>
                        </Nav>
                        </div>
                        
                    </div>
                </div>
            </div>
        </section>
        <BackToTopBtn/>
        <Footer/>
    </div>
    )
}

export default RJS5
