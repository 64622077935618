import React from 'react'
import Nav from 'react-bootstrap/Nav';
import {  Routes, NavLink, Route} from "react-router-dom";
import ShoppingCart from './ShoppingCart';
import HospitalPlus from './HospitalPlus';
import Ftbee from './Ftbee';
import Slider from "react-slick";

const TestimonialSlider = () => {
    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };
      return (
        <div>
          <div className="img-container mx-4 my-4 p-4 shadow-lg rounded">
            <Slider {...settings}>
              <div className="images">
                <div className="row"><embed src="images/portfolio/shopping-cart/shopping-cart.jpg" className="rounded-top img-fluid w-100 h-100" alt="" /></div>
                  <Nav>
                    <NavLink className="row bg-primary rounded-bottom pt-1 py-3 w-100" to="/shoppingcart"><button className="card-title mx-auto btn1 rounded fs-5 text-uppercase">View Details<i class="fa fa-hand-pointer fw-light fs-6 ps-2 xbootstrap ms-1"></i></button></NavLink>
                  </Nav>
                  <div className=" rounded ">
                    <Routes>
                      <Route  path="/shoppingcart" element={ <ShoppingCart />} />
                    </Routes>
                  </div>
              </div>
              <div className="images">
                  <div className="row"><embed src="images/portfolio/labels/label- fruit juice.png" className="rounded-top img-fluid w-100 h-100" alt="" /></div>
                  <div className="row bg-primary rounded-bottom pb"></div>
              </div>
              <div className="images">
                  <div className="row"><embed src="images/portfolio/hospital+/hospital+.png" className="rounded-top img-fluid w-100 h-100" alt="" /></div>
                  <Nav>
                    <NavLink className="row bg-primary rounded-bottom pt-1 py-3 w-100" to="/hospitalplus"><button className="card-title mx-auto btn1 rounded fs-5 text-uppercase">View Details<i class="fa fa-hand-pointer fw-light fs-6 ps-2 xbootstrap ms-1"></i></button></NavLink>
                  </Nav>
                  <div className=" rounded ">
                    <Routes>
                      <Route  path="/hospitalplus" element={ <HospitalPlus />} />
                    </Routes>
                  </div>
              </div>
              <div className="images"> 
                <div className="row"><embed src="images/portfolio/branding/branding1.png" className="rounded-top img-fluid w-100 h-100" alt="" /></div>
                <div className="row bg-primary rounded-bottom pb"></div>
              </div>
              <div className="images">
                  <div className="row"><embed src="images/portfolio/ftbee-web-app/1.png" className="rounded-top img-fluid w-100 h-100" alt="" /></div>
                  <Nav>
                    <NavLink className="row bg-primary rounded-bottom pt-1 py-3 w-100" to="/ftbee"><button className="card-title mx-auto btn1 rounded fs-5 text-uppercase">View Details<i class="fa fa-hand-pointer fw-light fs-6 ps-2 xbootstrap ms-1"></i></button></NavLink>
                  </Nav>
                  <div className=" rounded ">
                    <Routes>
                      <Route  path="/ftbee" element={ <Ftbee />} />
                    </Routes>
                  </div>
              </div>
              <div className="images"> 
                <div className="row"><embed src="images/portfolio/branding/branding.png" className="rounded-top img-fluid w-100 h-100" alt="" /></div>
                <div className="row bg-primary rounded-bottom pb"></div>
              </div>
            </Slider>
          </div>
        </div>
      );
    
}

export default TestimonialSlider