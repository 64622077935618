import React from 'react';
import Carousel from 'react-bootstrap/Carousel';

function Carouselslider() {

  return (
    <Carousel id="carouselExampleSlidesOnly" data-ride="carousel"className='carousel slide shadow-lg m-4 hover-wrapper rounded'>
      <Carousel.Item interval={8000}>
        <div className='row align-items-centr h-100 py-3'>
          <div className='col-lg-6 col-md-6 col-sm-10 mx-auto my-auto'>
            <p className="text-dark justify-content-center fs-2 p-4">Hi! I'm Swapnil Takawale, UI designer and front-end developer from Pune.</p>
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12'> <embed 
              className="img-thumbnail img-fluid h-100 w-100 rounded-circle border shadow-lg bg-blur border-gray"
              src="images/download.png"
              alt="First slide"
            />
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item interval={8000}>
        <div className='row align-items-centr h-100 py-3'>
          <div className='col-lg-6 col-md-6 col-sm-10 mx-auto my-auto'>
            <p className="text-dark justify-content-center fs-2 p-4">I like to design smart user interfaces and develope interactive web apps</p>
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12'> <embed 
              className="img-thumbnail img-fluid h-100 w-100 rounded-circle border shadow-lg  rounded bg-blur border-gray"
              src="images/images.png"
              alt="First slide"
            />
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item interval={8000}>
        <div className='row align-items-centr h-100 py-3'>
          <div className='col-lg-6 col-md-6 col-sm-10 mx-auto my-auto'>
            <p className="text-dark justify-content-center fs-2 p-4">I focus on designing and developing web-apps that has user-centric interface.</p>
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12'><embed
              className="img-thumbnail img-fluid h-100 w-100 rounded-circle border shadow-lg  rounded bg-blur border-gray p-2"
              src="images/images3.png"
              alt="Second slide"/>
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item interval={8000}>
        <div className='row align-items-centr h-100 py-3'>
          <div className='col-lg-6 col-md-6 col-sm-10 mx-auto my-auto'>
            <p className="text-dark justify-content-center fs-2 p-4">I always focus on cerating UI designs that engage the customers with the brand.</p>
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12'><embed
              className="img-thumbnail img-fluid h-100 w-100 rounded-circle border shadow-lg  rounded bg-blur border-gray p-2"
              src="images/image5.png"
              alt="Second slide"/>
          </div>
        </div>
      </Carousel.Item>
    </Carousel>
  );
}

export default Carouselslider;