import React from "react";
import Nav from 'react-bootstrap/Nav';
import {  Routes, NavLink, Route} from "react-router-dom";
import Header from './Header';
import Footer from './Footer';
import Comment from "./Comment";
import SBlog10 from './SBlog10';
import SBlog9 from './SBlog9';
import SBlog8 from './SBlog8';
import Blogs from './Blogs';
import BackToTopBtn from "./BackToTopBtn";

const SBlog7 = () => {
   
  return (
    <div className="overflow-hidden">
        <Header/>

        <section className="bg-primary position-relative">
        <div className="container">
            <div className="row">
            <div className="col-12 text-center">
                <h3 className="text-white font-tertiary pt-2">Design System </h3><h5 className="text-white font-tertiary pt-2">(all that you need to know about the design system)</h5>
            </div>
            </div>
        </div>
        
        
        </section>


        <section className="section">
        <div className="bg-blur rounded">
            <div className="row p-3">
            <div className="col-lg-12">
                
                <p className="text-white px-4">Published by <span className="text-white font-Croissant">tcom</span></p>
                <div className="content rounded">
                <embed src="images/blog/post-7.png" alt="post-thumb" className="img-fluid rounded float-center mr-5 mb-4" />
                <h3 className="text-dark">Design System</h3>
                <p>A design system is a documented catalog of components and styles used within a product, including how and why each should be implemented. While design systems are often confused with style guides and pattern libraries, a design system is more strategic and high-reaching. In fact, pattern libraries and style guides are both pieces of the design system!</p>
                
                <h3 className="text-dark">Why We Need Design System and what is it's Purpose?</h3>
                <p>As UI design has evolved over the years, the scale and speed at which UI screens must be created has also increased. Not only are there millions of applications and billions of websites (with more created each year), but each of those apps and websites might have hundreds or thousands of pages (or screens). With this drastic expansion comes a dire need for organizations to streamline design work. So, many design teams leverage robust design systems to manage designs at scale.
                </p>
                <p>The primary purpose of design systems is to increase efficiency in product development. With a consistent design language in place, it becomes easier to design products at scale that are consistent in aesthetics, user interface, and user experience. Design systems also promote collaboration, since handoffs across distributed teams become easier.</p>
                <h3 className="text-dark">Different types of Design Systems</h3>
                <p>When implementing design systems in organizations, it is good to know about the different kinds. The choice can depend on the size of the organization, the number of products and platforms, maturity of the users, etc. Let’s have a look at the six different kinds of design systems. </p>
                <embed src="images/blog/Types_of_Design_Systems.jpg" alt="post-thumb" className="img-fluid rounded float-center mx-5 px-5 mb-4" />
                <h4 className="text-dark">01. Strict System</h4>
                <p>A strict system is very broad and comprehensive. It covers almost all use-cases. Every new addition to the system is done after a strict screening process. Such systems have detailed documentation and through coordination between design and development.</p>
                <h4 className="text-dark">02. Loose System</h4>
                <p>A loose system, provides more freedom to the team. Here, the idea is to set up a basic framework while leaving enough space for experimentation and creativity. The team has the flexibility to either use it or not, depending on the situation.</p>
                <h4 className="text-dark">03. Modular System</h4>
                <p>A modular system is composed of multiple parts that are reusable and interchangeable. It is useful for large scale designs like that of ecommerce UX or finance applications. A modular system makes it easy to scale projects quickly and is flexible to varying design requirements.</p>
                <h4 className="text-dark">04. Integrated System</h4>
                <p>An integrated system is also composed of parts, but they are not interchangeable. These systems are created for specific circumstances and hence may not have many repeating parts. They are used to simplify designs that require a lot of creative thinking, like portfolios and marketing campaigns.</p>
                <h4 className="text-dark">05. Centralized System</h4>
                <p>A centralized system has a dedicated team for managing the design system. They are responsible for ensuring that the system meets the needs of users. It is their role to oversee the works of others and evolve the system as required.</p>
                <h4 className="text-dark">06. Destributed System</h4>
                <p>In a distributed system, several people from different teams manage the design system and also use it in their daily work. As most users are directly involved and have a feeling of membership, distributed systems have higher chances of adoption.</p>
                
                <h3 className="text-dark">Elements of a Design System</h3>
                <p>The key elements that compose a design system can be categorized into two – tangible and intangible.</p>

                <h4 className="text-dark">Tangible Elements</h4>
                <p>Tangible elements consist of the design artifacts. These are:</p>

                <li><b>Style guide: </b> A style guide serves as a guideline for the visual design decisions. It encompasses aspects such as colors, typography, and iconography. Style guides are useful in creating a brand identity and bringing consistency to design across all touchpoints.</li>
                <li><b>Components: </b> Components are the individual UI elements, like buttons and text fields, that make the interface. Each component is created by designers and coded by developers. Reusing components helps designers minimize their time and effort.</li>
                <li><b>Patterns: </b> Patterns are libraries where the individual components are grouped together based on contexts to facilitate ease of use. Standardized patterns bring familiarity and consistency to designs.</li>

                <h4 className="text-dark">Intangible Elements</h4>
                <p>Intangible elements help a design system have direction and unity. They consist of:</p>

                <li><b>Design purpose: </b> Aligning a design team around a design purpose/philosophy will strengthen the team and create a bonding. It helps the team have a clear set of goals and define the values that shape all aspects of the design.</li>
                <li><b>Design principles: </b> The principles provide a set of rules that guide the design team on how to use the components, patterns, and style guide to create a consistent design experience.</li>
                <li><b>Documentation: </b> Documentation describes the design system. It records everything from the UI pattern use cases to visuals, component attributes, code standards, best practices, etc.</li>

                <h3 className="text-dark">Steps to Build a Design System</h3>

                <p>Before creating a design system, it's important to know what you need and why. Ask these questions</p>
                <li>What is my company trying to accomplish?</li>
                <li>What problems am I trying to solve?</li>
                <li>Who does my company consider the customer, and how will they use the product?</li>
                <li>Which platforms or devices do we need to support?</li>
                <li>Is there a standard way of doing things already?</li>

                <p>Once you have a decent understanding of your company's goals and needs, it's time to start creating your system. Here are the steps that will take you through the process:</p>

                <p>Step 1: Define the purpose</p>
                <p>Before beginning the actual design work, it’s important to define the purpose of the design system. What are you building, and why? This will help to understand how the design system should be, who needs to be involved, what values should be relayed and what objectives are to be achieved.</p>

                <p>Step 2: Perform a visual audit</p>
                <p>With the purpose in mind, perform a visual audit of the current design. Identify the visual elements that are in use, their quality, how well they align with the design philosophy, etc. Analyzing these will help estimate the effort that would be required to build a design system.</p>

                <p>Step 3: Create a design language</p>
                <p>The design language is at the heart of a design system. In this step, the focus is on creating a style guide for the system. Colors, typography, and imagery form the core of the design language and they should reflect throughout the digital product.</p>

                <p>Step 4: Build a pattern library</p>
                <p>The next step is to build the UI components and pattern library. Here the actual components of the UI are examined at a micro-level. They are collected and merged to form the library.</p>

                <p>Step 5: Documentation</p>
                <p>The last step, documentation, is an unavoidable aspect of design systems. In fact, it is the aspect that separates a design system from a normal pattern library. It is best to bring design and implementation into the documentation as it will give both designers and developers a complete overview rather than just knowing their own part. This will help them make better design decisions.</p>

                <h3 className="text-dark">Examples of Design Systems</h3>
                <p>Most design systems follow the same structure. They usually have a main navigation for the primary categories like branding, components, code, etc. These categories have sub navigations that frame every detail such as colors, guidelines, and typography. Exploring design systems will give much inspiration and learning opportunities to create your own system.</p>

                <p>Take a look at the five best design systems of renowned organizations. </p>

                <h4 className="text-dark">Google Material Design System</h4>
                <p>When Google published Material Design, it was a turning point in the history of design systems and served as an inspiration to many. Its detailed categorization and cataloging of components stood out and gave order to design processes. Their design philosophy is to create a visual language that combines classic design principles with innovations in technology and provides a unified user experience across all channels. </p>
                <p>One of the most well-known design systems is Google's Material Design. It was first introduced in 2014 to unify the design of all Google products.
                   It has been used by numerous companies and apps, such as YouTube, Twitter, and Uber ever since.</p>
                <embed src="images/blog/material-design.jpg" alt="post-thumb" className="img-fluid rounded float-center mx-5 px-5 mb-4 w-75" />   

                <h4 className="text-dark">Apple Human Interface Guidelines</h4>
                <p>Apple’s designs are known for their elegance. The design philosophy of Human Interface Guidelines is built on precision, simplicity, empathy, and focus. It provides several developers and designer resources like templates, guidelines, and best practices that people can use in their projects. </p>
                <p>First introduced in 1983 with the release of the Apple Lisa, Apple Human Interface Guidelines have been updated multiple times to keep up with the changing design trends.
                   Today, they are used by many companies and apps, including Facebook and LinkedIn.</p>
                <embed src="images/blog/human-interface.png" alt="post-thumb" className="img-fluid rounded float-center mx-5 px-5 mb-4 w-75" />   

                <h4 className="text-dark">Microsoft Fluent Design System</h4>
                <p>Fluent Design System emphasizes on inclusivity and designing for all ranges of human diversity. Their design philosophy is to learn from diversity, recognize exclusions and use them as opportunities to create inclusive designs. They provide open-source components and design tools that people can use to recreate the look of the Microsoft ecosystem. </p>
                <embed src="images/blog/fluent-design.gif" alt="post-thumb" className="img-fluid rounded float-center mx-5 px-5 mb-4 w-75" />

                <h4 className="text-dark">IBM Carbon Design System</h4>
                <p>IBM's Carbon Design System was released in 2013 and is used by IBM primarily and numerous companies worldwide.</p>   
                <p>The main features of the system include navigation, layout, space, typography, pattern libraries, style guides and color. There are also rules for positioning components within a form view and applying different states to it.</p>
                <embed src="images/blog/carbon-design.png" alt="post-thumb" className="img-fluid rounded float-center mx-5 px-5 mb-4 w-75" />

                <h4 className="text-dark">Shopify Design System</h4>
                <p>An ecommerce platform trusted by more than 1 million users, the Shopify design system aims to help people start, run, and grow their businesses easily. The values they share through their design system are empowerment, efficiency, familiarity, and trustworthiness. For ecommerce designs, Shopify’s system is a great source of knowledge and inspiration.</p>
                <p>Shopify Polaris was first introduced in 2016. It's used to design and build apps and websites for Shopify.
                The Polaris system is based on modularity, which means that all the elements are self-contained and can be used interchangeably. This makes it easy to create designs for different platforms with this style guides.</p>
                <embed src="images/blog/shopify-polaris.jpg" alt="post-thumb" className="img-fluid rounded float-center mx-5 px-5 mb-4 w-75" />

                <h3 className="text-dark">Conclusion</h3>
                <p>A design system is a comprehensive source of information. It can bring a design team together on the same ground and facilitate collaboration. For organizations working remotely with a globally distributed team, design systems are a blessing. It helps to institute direction, avoid overlap, and empower everyone to take right design decisions. </p>
                <p>For a design system to impart maximum benefits to an organization, it is important to make it unique to that organization. Different companies may have different objectives, hence a system that works for one need not work for another. So before building a design system, analyze the unique purpose, the problems to be addressed and the ways design systems can help. It can be a little work, but in the long run, it pays off and makes the organization quite efficient.</p>
                </div>
            </div>
            </div>
        </div>
        </section>

        <section>
            <Comment/>
        </section>

        <section className="section mb-200">
        <div className="bg-blur rounded px-3">
            <div className="row">
            <div className="col-12 text-center">
                <h2 className="text-primary text-uppercase rounded">Similar Posts</h2>
            </div>
            <div className="col-lg-4 col-sm-6 mb-4">
                <article className="card shadow bg-blur">
                <embed className="rounded card-img-top blog-img" src="images/blog/post-8.png" alt="post-thumb" />
                <div className="card-body">
                    <h4 className="card-title text-dark">Wireframing VS Mockup VS Prototype 
                    </h4>
                    <p className="cars-text">The product design process starts with a simple sketch on a blank piece of paper. Then, wireframe is create to organize the content and features of your app. The next step is to add colors, icons, pictures, and logos to the wireframe so the mockup is created. The process is finished with the creation of prototype by bringing the mockup to life with interactive elements.</p>
                    <Nav>
                        <NavLink className="p-2 my-4 rounded  nav-link btn btn-xs btn-primary"  to="/sblog8"><b>Read More</b></NavLink>
                    </Nav>
                    <div className="  rounded">
                    <Routes>
                        <Route  path="/sblog8" element={ <SBlog8 />} />
                    </Routes>
                    </div>  
                </div>
                </article>
            </div>
            <div className="col-lg-4 col-sm-6 mb-4">
                <article className="card shadow bg-blur">
                <embed className="rounded card-img-top blog-img" src="images/blog/ux-research.png" alt="post-thumb" />
                <div className="card-body">
                    <h4 className="card-title text-dark">UX Research: The Ultimate Guide About UX Researchers
                    </h4>
                    <p className="cars-text">UX (user experience) research is the systematic study of target users and their requirements, to add realistic contexts and insights to design processes.</p>
                    <p className="cars-text">This comprehensive guide to UX research dives into the fundamentals of research and its various methods and includes tips and best practices.</p>
                    <Nav>
                        <NavLink className="p-2 my-4 rounded  nav-link btn btn-xs btn-primary"  to="/sblog9"><b>Read More</b></NavLink>
                    </Nav>
                    <div className="  rounded">
                    <Routes>
                        <Route  path="/sblog9" element={ <SBlog9 />} />
                    </Routes>
                    </div>  
                </div>
                </article>
            </div>
            <div className="col-lg-4 col-sm-6 mb-4">
                <article className="card shadow bg-blur">
                <embed className="rounded card-img-top blog-img" src="images/blog/images.jpg" alt="post-thumb" />
                <div className="card-body">
                    <h4 className="card-title text-dark">Mobile App vs Web App vs Website: Which Is Better And Why?
                    </h4>
                    <p className="cars-text ">First of all you will have to decide whether you’ll create a mobile app, a web app, or a website before setting out to build a digital product. We don’t often think why any company build a mobile app over a web app or a website and vice versa, it can be a challenging decision when it comes to your own product.<br/> Here’s what you need to know about the differences between mobile apps, web apps, and websites so you can make the right choice for your business.</p>
                    <Nav>
                        <NavLink className="p-2 my-4 rounded  nav-link btn btn-xs btn-primary"  to="/sblog10"><b>Read More</b></NavLink>
                    </Nav>
                    <div className="  rounded">
                    <Routes>
                        <Route  path="/sblog10" element={ <SBlog10 />} />
                    </Routes>
                    </div>  
                </div>
                </article>
            </div>
            </div>
            <div className=" pagination w-100 ">
                <Routes>
                    <Route  path="/blogs" element={ <Blogs />} />
                </Routes>
            </div>  
            <Nav aria-label="Page navigation example" className="px-2">
                <NavLink className="page-link text-dark rounded btn-primary p-2 mb-5"  to="/blogs"><b>Back</b></NavLink>
            </Nav>
        </div>
        </section>  
        <BackToTopBtn/>
        <Footer/>  
    </div>
    )
}

export default SBlog7

