import React from 'react';
import BlogTabs from './BlogTabs';
import Header from './Header';
import Footer from './Footer';
import BackToTopBtn from "./BackToTopBtn";

const Portfolio = () => {
  return (
    <div>
      <Header/>
      <section classNameName="heading-area" id="parallax">
      </section>

      <section className="mb-200">
        <div className="bg-primary position-relative">
        <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                  <h3 className="text-white font-tertiary pt-2">Blog</h3>
              </div>
            </div>
        </div>
        </div>
        <BlogTabs />
      </section> 
      <BackToTopBtn />
      <Footer/>
    </div>
  )
}

export default Portfolio