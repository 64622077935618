import React, { useEffect, useState } from 'react';
import { db } from '../firebase';
import {addDoc, collection} from 'firebase/firestore';
import Header from './Header';
import Footer from './Footer';
import BackToTopBtn from './BackToTopBtn';

const Contact = (callback) => {
  /*const [uname, setUName] = useState("");
  const [email, setEmail] = useState("");
  const [tel, setTel] = useState("");
  const [message, setMessage] = useState("");*/
  const dataCollection = collection(db, "contactinfo");
  let disableButton= true;
 

  const [inputValues, setInputValue] = useState({
    uname: "",
    email: "",
    tel: "",
    message: ""
  });

  const [validation, setValidation] = useState({
    uname: "",
    email: "",
    tel: "",
    message: "",
  });
  

  const checkValidation = () => {
    let errors = {};

    //name validation
    if (!inputValues.uname.trim()) {
      errors.uname = "Name is required";
    } else {
      errors.uname = "";
    }

    // email validation
    if (!inputValues.email.trim()) {
      errors.email = "Email is required";
    } else if (!/^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(inputValues.email)) {
      errors.email = "Please enter a valid email address";
    } else {
      errors.email = "";
    }
   
    //mobile no. validation
    const tel = inputValues.tel;
    if (tel.length < 10 || tel.length > 10) {
      errors.tel = "Mobile No. must be equal to 10 digits";
    } else if (!/^(?=.*[0-9]).{10}$/.test(tel)) {
      errors.tel = "Mobile No. must contain only digits";
    } else {
      errors.tel = "";
    }

     //message validation
     if (inputValues.message.length < 50) {
      errors.message = "Your Message must contain atleast 50 characters";
    } else if (inputValues.message.length >= 100) {
      errors.message = "Your Message must not contain more than 100 characters";
    } else {
      errors.message = "";
    }
    

    setValidation(errors); 
  };
  if(inputValues.uname!=="" && inputValues.email!=="" && inputValues.tel!=="" && inputValues.message!==""){
    
    if(!validation.uname && !validation.email && !validation.tel && !validation.message){
        disableButton= false;
    }
  }
  else{
    disableButton= true;
  }

   function handleChange(event){
    const { name, value } = event.target;
    setInputValue({ ...inputValues, [name]: value });
    console.log(event.target.value)

  }

  const handelSubmit = (e) => {
    e.preventDefault();
    
    addDoc (dataCollection,{
        uname: inputValues.uname,
        email: inputValues.email,
        tel: inputValues.tel,
        message: inputValues.message,
    });

    setInputValue({
      uname: '',
      email: '',
      tel: '',
      message: ''
    })
  } ;
  


  useEffect(() => {
    checkValidation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValues]);

  return (
    <div>
      <Header/>
      <section className="section section-on-footer mb-2 mb-200 bg-blur mx-2 mt-4 rounded" data-background="images/backgrounds/bg-dots.png">
        <div className="container">
          <div className="row">
            <div className="col-lg-10">
                <div className="rounded  p-5 mb-40 bg-primary">
                <h3 className="font-weight-bold mb-5 border-bottom pb-3 text-white">Contact Me</h3>
                <form method="POST" onSubmit={handelSubmit}>
                    <div className="col-md-10">
                    <input placeholder="Your name *" name="uname" id="uname" type="text" tabindex="1" autofocus className="form-control px-3 mb-4 rounded"   onChange={(e) => handleChange(e)} value={inputValues.uname}/>
                    {validation.uname && <p className="text-white">{validation.uname}</p>}
                    
                    </div>
                    <div className="col-md-10">
                    <input placeholder="Your Email Address *" name="email" id="email" type="email" tabindex="2" className="form-control px-3 mb-4 rounded" onChange={(e) => handleChange(e)} value={inputValues.email}/>
                    {validation.email && <p className="text-white">{validation.email}</p>}
                    </div>
                    <div className="col-md-10">
                    <input placeholder="Your Phone Number *" name="tel" type="tel" id="tel" tabindex="3" className="form-control px-3 mb-4 rounded" onChange={(e) => handleChange(e)} value={inputValues.tel}  />
                    {validation.tel && <p className="text-white">{validation.tel}</p>}
                    </div>
                    <div className="col-md-10">
                    <textarea placeholder="Type your Message" type="text" name="message" id="message" tabindex="4" className="form-control px-3 mb-4 rounded" onChange={(e) => handleChange(e)} value={inputValues.message}/>
                    {validation.message && <p className="text-white">{validation.message}</p>}
                    </div>
                    <div>
                        
                    </div>
                    <div className="col-lg-6 col-10 py-3 mx-auto">
                    <button type="submit" className="btn btn-primary w-100 rounded" disabled={disableButton}>Submit</button>
                    </div>
                </form>  
                </div>
            </div>
          </div>
        </div>
      </section>
        <BackToTopBtn/>
       <Footer/>
    </div>
  )
}

export default Contact
