import React, { useEffect, useState } from "react";
import { db } from '../firebase';
import {addDoc, collection} from 'firebase/firestore';

const Comment = () => {
  const dataCollection = collection(db, "comments");
  let disableButton= true;

  const [inputValues, setInputValue] = useState({
    uname: '',
    email: '',
    message: ''
  });

  const [validation, setValidation] = useState({
    uname: '',
    email: '',
    message: ''
  });


  const checkValidation = () => {
    let errors = {};

    //name validation
    if (!inputValues.uname.trim()) {
      errors.uname = "Name is required";
    } else {
      errors.uname = "";
    }

    // email validation
    if (!inputValues.email.trim()) {
      errors.email = "Email is required";
    } else if (!/^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(inputValues.email)) {
      errors.email = "Please enter a valid email address";
    } else {
      errors.email = "";
    }

     //message validation
     if (!inputValues.message.trim()) {
      errors.message = "Comment is required";
     }else if (inputValues.message.length < 20) {
      errors.message = "Your Comment must contain atleast 20 characters";
    } else if (inputValues.message.length >= 100) {
      errors.message = "Your Comment must not contain more than 100 characters";
    } else {
      errors.message = "";
    }
    

    setValidation(errors);
  };
  if(inputValues.uname!=="" && inputValues.email!=="" && inputValues.message!==""){
    
    if(!validation.uname && !validation.email && !validation.message){
        console.log("hello");
        disableButton= false;
    }
  }
  else{
    console.log("hello2");
    disableButton= true;
  }

   function handleChange(event){
    const { name, value } = event.target;
    setInputValue({ ...inputValues, [name]: value });
    
  }
  const handelSubmit = (e) => {
    e.preventDefault();
    
    addDoc (dataCollection,{
        uname: inputValues.uname,
        email: inputValues.email,
        message: inputValues.message,
    });
    console.warn("addDoc", addDoc);

    setInputValue({
      uname: '',
      email: '',
      message: ''
    })
  } ;
  

  useEffect(() => {
    checkValidation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValues]);

  return (
    <div>
       <section className="mx-3">
            <div className="p-4 1p-4 bg-blur rounded bg-primary">
                <h3 className="font-weight-bold mb-3 border-bottom pb-3 text-white">Leave a Comment</h3>
                <form method="POST" onSubmit={handelSubmit} className="row">
                  <div className="col-md-6 pe-3">
                      <input type="text" className="form-control mb-3 rounded" placeholder=" Your Name *" name="uname" id="uname" onChange={(e) => handleChange(e)} value={inputValues.uname} />
                      {validation.uname && <p className="text-white">{validation.uname}</p>}
                      <input type="text" className="form-control mb-3 rounded" placeholder=" Your Email *" name="email" id="email" onChange={(e) => handleChange(e)} value={inputValues.email} />
                      {validation.email && <p className="text-white">{validation.email}</p>}
                  </div>
                  <div className="col-md-6">
                      <textarea name="message" id="message" placeholder="We appreciate your comment on this post*" className="form-control mb-4 rounded" onChange={(e) => handleChange(e)} value={inputValues.message}/>
                      {validation.message && <p className="text-white">{validation.message}</p>}
                      <button type="submit"  className="btn btn-primary border w-75 mx-4 rounded"  disabled={disableButton} >Submit</button>
                  </div>
                </form>
            </div>
        </section>
    </div>
  )
}

export default Comment
