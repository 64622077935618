import React from "react";
import Comment from "./Comment";
import Nav from 'react-bootstrap/Nav';
import {  Routes, NavLink, Route} from "react-router-dom";
import Header from './Header';
import Footer from './Footer';
import SBlog1 from './SBlog1';
import SBlog2 from './SBlog2';
import SBlog3 from './SBlog3';
import Blogs from "./Blogs";
import BackToTopBtn from "./BackToTopBtn";

const SBlog4 = () => {
   
  return (
    <div className="overflow-hidden">
        <Header/>

        <section className="bg-primary position-relative">
        <div className="container">
            <div className="row">
            <div className="col-12 text-center">
                <h3 className="text-white font-tertiary pt-2">Design Process</h3>
            </div>
            </div>
        </div>
        
        
        </section>


        <section className="section">
        <div className="bg-blur rounded">
            <div className="row p-3">
            <div className="col-lg-12">
                
                <p className="text-white px-4">Published by <span className="text-white font-Croissant">tcom</span></p>
                <div className="content rounded">
                <h4 className="text-dark">What is the design process? </h4>
                <p>The design process is a tool that helps you break down large projects into smaller, easier-to handle stages. It's prominent in engineering, architecture, and manufacturing because it helps companies deliver finished solutions that customers want and need.
                <b class="text-dark">Design process is a way of figuring out what you need to do, then do it.</b></p>
                <p>The first critical step to understanding the design process is that it’s not about working the “right way” or “wrong way”. The design process is a method you use to be more creative, productive, and accurate. The process should conform to you, rather than the other way around. It’s not a rigid list of steps to follow, but a tool you use to do better work.</p>
                
                <h4 className="text-dark">Why design process is important</h4>
                <li>Design process helps designers to be reliable and creative without depending upon inspiration.</li>
                <li>Design process provides checks to ensure creative concepts don’t direct design or desigmers in wrong direction.</li>

                    <h4 class="text-dark">Design Process Steps</h4>
                    <embed src="images/blog/post-4.jpg" alt="post-thumb" className="img-fluid rounded float-center mr-5 mb-4 w-75" />
                    
                    <p> <h5 class="text-dark">1. Listen to the Client</h5>
                    The first most crucial step towards designing a product be it for anything it is important to actively listen to the client, this is not only important to maintain a healthy client relationship but it is also important in your design process. Listen means understanding your client what he wants and what he expects from you, ask a lot of questions, build a strong relationship with them and understand them flawlessly.</p>  

                    <p> <h5 class="text-dark">2. Analyze the Project</h5>
                    The second most crucial step in the whole design process is to evaluate whether or not the project at hand is possible or not. One of the most important things to note in this step is that this should always be done in sequential order. As the designer it is our job to identify ideas that might not be possible within the client’s budget due to any reason, suggest an alternating solutions for those ideas. While analyzing the project you feel like something won’t be possible or you find a problem you should immediately consult with the client and explain the problem and clear your queries.</p> 

                    <p> <h5 class="text-dark">3. Design a product</h5>
                    To put it simply, there is no shortcut to designing and knowing how your website will look. It’s not like someone can tell you this is how you need to design a product and you start working. Designing is a combination of art and science, and with every art it depends on the artist on how he/she goes about their artwork and presents an end product to the client. The essence of this part is that you know how to design the product and now it's the time to use your skills and knowledge to make that product for your client. If designing the product seems difficult go back to the 2nds step and analyze the project again.</p> 

                    <p> <h5 class="text-dark">4. Test the product</h5>
                    Lastly, before you complete your project and submit the design to your client it is important to first test the product to see if all the elements in the design are interacting with one another successfully. You wont like to deliver a product that keeps on crashing and keeps disturbing your client.
                    If the test proves your design to be correct then well and good however if it proves that the design is flawed and the product will eventually crash then evaluate your project on either of the previous three steps based on the severity of the problem.</p> 

                    <h4 className="text-dark">Conclusion</h4>
                    <p>An important thing to note after all this is that this process is not final. Like I said earlier, this model will only serve as a guide to organize your work so that you don’t get stuck at any stage and easily compartmentalize your work. However, the essence of this is to show you that when you organize your tasks you make your work easier for yourself and ultimately end up improving your design process.
                    </p>
                </div>
            </div>
            </div>
        </div>
        </section>
    
        <section>
            <Comment/>
        </section>

        <section className="section mb-200">
        <div className="bg-blur rounded px-3">
        <div className="row p-3">
        <div className="col-12 text-center">
                <h2 className="text-primary text-uppercase rounded">Similar Posts</h2>
            </div>
                <div className="col-lg-4 col-sm-6 mb-4">
                    <article className="card shadow bg-blur">
                        <embed className="rounded card-img-top blog-img" src="images/blog/post-1.jpg" alt="post-thumb" />
                        <div className="card-body">
                            <h4 className="card-title text-dark">User Centered Design
                            </h4>
                            <p className="cars-text">User-centered design (UCD) is an iterative design process in which designers focus on the users and their needs in each phase of the design process. In UCD, design teams involve users throughout the design process via a variety of research and design techniques, to create highly usable and accessible products for them.</p>
                            <Nav>
                            <NavLink className="p-2 my-4 rounded  nav-link btn btn-xs btn-primary"  to="/sblog1"><b>Read More</b></NavLink>
                            </Nav>
                            <div className="  rounded">
                            <Routes>
                                <Route  path="/sblog1" element={ <SBlog1 />} />
                            </Routes>
                            </div>    
                        </div>
                    </article>
                </div>
                <div className="col-lg-4 col-sm-6 mb-4">
                <article className="card shadow bg-blur">
                <embed className="rounded card-img-top blog-img" src="images/blog/post-2.jpg" alt="post-thumb" />
                <div className="card-body">
                    <h4 className="card-title text-dark">Purpose of UI/UX Design
                    </h4>
                    <p className="cars-text">The UI & UX Design are two different design process help to win the consumers' confidence and make them use your application or website providing them what they are looking for. The number of consumers you are getting on your website/application can measure the success of the great UI and UX process you adapt while designing an application.</p>
                    <Nav>
                        <NavLink className="p-2 my-4 rounded  nav-link btn btn-xs btn-primary"  to="/sblog2"><b>Read More</b></NavLink>
                    </Nav>
                    <div className="  rounded">
                    <Routes>
                        <Route  path="/sblog2" element={ <SBlog2 />} />
                    </Routes>
                    </div>  
                </div>
                </article>
            </div>
            <div className="col-lg-4 col-sm-6 mb-4">
                <article className="card shadow bg-blur">
                    <embed className="rounded card-img-top blog-img" src="images/blog/post-3.jpg" alt="post-thumb" />
                    <div className="card-body">
                        <h4 className="card-title text-dark">Understanding UI Design and its Principles
                        </h4>
                        <p className="cars-text">An integral part of any website or application, user interface design is also arguably one of the most important. This is because your design is what your users see and interact with.</p>
                        <Nav>
                            <NavLink className="p-2 my-4 rounded  nav-link btn btn-xs btn-primary"  to="/sblog3"><b>Read More</b></NavLink>
                        </Nav>
                        <div className="  rounded">
                        <Routes>
                            <Route  path="/sblog3" element={ <SBlog3 />} />
                        </Routes>
                        </div>  
                    </div>
                </article>
            </div>
            </div>
            <div className=" pagination w-100 ">
                <Routes>
                    <Route  path="/blogs" element={ <Blogs />} />
                </Routes>
            </div>  
            <Nav aria-label="Page navigation example" className="px-2">
                <NavLink className="page-link text-dark rounded btn-primary p-2 mb-5"  to="/blogs"><b>Back</b></NavLink>
            </Nav>
        </div>
        </section> 
        <BackToTopBtn/>
        <Footer/>   
    </div>
    )
}

export default SBlog4

