import React, {useState, useEffect} from "react";
import Nav from 'react-bootstrap/Nav';
import {  Routes, NavLink, Route} from "react-router-dom";
import Markdown from "markdown-to-jsx";	
import Header from '../Header';
import Footer from '../Footer';
import Code from "../Code";
import RJS2 from './RJS2';
import RJS4 from './RJS4';
import Sidebar from "../Sidebar";
import BackToTopBtn from "../BackToTopBtn";

const RJS3= () => {
    const [postContent0, setPostcontent0] = useState('');
    const [postContent1, setPostcontent1] = useState('');
    const [isDark, setIsDark] = useState(true);

    useEffect(() => {
       
        import('./../ReactJS-Tutorials/Tutorial6.md')
        .then(res =>
          fetch(res.default)
            .then(response => response.text())
            .then(response => setPostcontent0(response))
            .catch(err => console.log(err))
        )
          import('./../ReactJS-Tutorials/Tutorial7.md')
          .then(res =>
            fetch(res.default)
              .then(response => response.text())
              .then(response => setPostcontent1(response))
              .catch(err => console.log(err))
          )
      }, [])
   
  return (
    <div className="overflow-hidden">
        <Header/>

        <section className="bg-primary position-relative">
            <div className="container">
                <div className="row">
                <div className="col-12 text-center">
                    <h3 className="text-white font-tertiary pt-2">ReactJS</h3>
                </div>
                </div>
            </div>
        </section>
        <section className="section mb-200">
            <div className="bg-blur rounded">
                <div className="row p-3">
                    <div className="col-lg-12">
                        
                        <p className="text-dark px-4">Published by <span className="text-dark font-Croissant">tcom</span></p>
                        <div className="content rounded">
                            <embed src="images/blog/ReactJS.png" alt="post-thumb" className="img-fluid rounded float-center mr-5 mb-4 w-100" />
                            <dib>
                                <Sidebar/>
                            </dib>
                            <h4 className="text-dark pt-5">ReactJS Hooks</h4>
                            <li>Hooks are a new addition in React 16.8. They let you use state and other React features without writing a class.</li>
                            <li>Hooks allow function components to "hook" into React features such as state and lifecycle methods.</li>
                            <li> Because of this, class components are generally no longer needed. </li>
                       
                           <h4 className="text-dark">Hook Rules</h4>
                           <li>Hooks can only be called inside React function components.</li>
                           <li>Hooks can only be called at the top level of a component.</li>
                           <li>Hooks cannot be conditional</li>

                           <p>While Redux, Recoil, and MobX are usually the best options for managing global state in large React applications, more often than necessary, many React developers jump into these third-party state management libraries when they could have effectively handled their state with Hooks.</p>
                           <p>When you consider the complexity of getting started with a third-party library like Redux, which is made much easier with Redux Toolkit, and the excessive amount of boilerplate code needed, managing state with React Hooks and the Context API becomes quite an appealing option. There’s no need to install an external package or add a bunch of files and folders to manage global state in our application.</p>
                           
                           <p><b>React version 18 provides 15 hooks for developers. With 15 hooks, you achieve similar functionality to a class-based component.</b></p>
                           <div className="row text-dark bg-blur">
                                <div className="col-lg-4 col-sm-6 border border-dark p-3 rounded">
                                    
                                    <ol>01. &nbsp;  useState</ol>
                                    <ol>02. &nbsp;  useEffect</ol>
                                    <ol>03. &nbsp;  useContext</ol>
                                    <ol>04. &nbsp;  useReducer</ol>
                                    <ol>05. &nbsp;  useMemo</ol>
                                
                                </div>
                                <div className="col-lg-4 col-sm-6 border border-dark p-3 rounded">
                                    
                                    <ol>06. &nbsp;  useCallback</ol>
                                    <ol>07. &nbsp;  useDebugValue</ol>
                                    <ol>08. &nbsp;  useRef</ol>
                                    <ol>09. &nbsp;  useLayoutEffect</ol>
                                    <ol>10. &nbsp;  useTransition</ol>
                                    
                                </div>
                                <div className="col-lg-4 col-sm-6 border border-dark p-3 rounded">
                                    
                                    <ol>11. &nbsp;  useDeferredValue</ol>
                                    <ol>12. &nbsp;  useId</ol>
                                    <ol>13. &nbsp;  useSyncExternalStore</ol>
                                    <ol>14. &nbsp;  useInsertionEffect</ol>
                                    <ol>15. &nbsp;  useImperativeHandle</ol>
                                    
                                </div>
                           </div>
                          
                           <h4 className="text-dark">1. useState Hook</h4>
                           <li>useState is React Hook that allows you to add state to a functional component.</li>
                           <li>It returns an array with two values: the current state and a function to update it. </li>
                           <li>The Hook takes an initial state value as an argument and returns an updated state value whenever the setter function is called.</li>
                           <li>The useState Hook can be used to keep track of strings, numbers, booleans, arrays, objects, and any combination of these! We could create multiple state Hooks to track individual values.</li>

                           <div className="bg-blur p-3 border border-dark text-dark rounded">
                            <b>const [state, setState] = useState(initialValue);</b>
                           </div>
                           
                           <p>Here, the initialValue is the value you want to start with, and state is the current state value that can be used in your component. 
                           The setState function can be used to update the state, triggering a re-render of your component.</p>
                            <div className="p-2 rounded">
                                <article>
                                    <header>
                                    </header>
                                    <main> 
                                    <Markdown
                                        options={{
                                        overrides: {
                                            Code: {
                                            component: Code,
                                            props: {
                                                isDark,
                                                setIsDark
                                            }
                                            }
                                        }
                                        }}
                                    >
                                        {postContent0}
                                    </Markdown>
                                    </main>
                                </article>
                            </div>

                            <h4 className="text-dark">Updating Objects and Arrays in State</h4>
                            <p>When state is updated, the entire state gets overwritten.<br/>
                            What if we only want to <b>update the color of our car?</b><br/>
                            If we only called <b>setCar(&#65371;color: "blue"&#65373;),</b> this would remove the brand, model, and year from our state.</p>
                            <p><b>We can use the JavaScript spread operator to help us.</b></p>
                            <div className="p-2 rounded">
                                <article>
                                    <header>
                                    </header>
                                    <main> 
                                    <Markdown
                                        options={{
                                        overrides: {
                                            Code: {
                                            component: Code,
                                            props: {
                                                isDark,
                                                setIsDark
                                            }
                                            }
                                        }
                                        }}
                                    >
                                        {postContent1}
                                    </Markdown>
                                    </main>
                                </article>
                            </div>

                        <div className=" pagination w-100">
                            <Routes>
                                <Route  path="/rjs2" element={ <RJS2 />} />
                                <Route  path="/rjs4" element={ <RJS4 />} />
                            </Routes>
                        </div>  
                        <Nav aria-label="Page navigation example">
                            <NavLink className="page-link text-dark rounded btn-primary p-2 mb-5 mx-4"  to="/rjs2"><b>Previous</b></NavLink>
                            <NavLink className="page-link text-dark rounded btn-primary p-2 mb-5"  to="/rjs4"><b>Next</b></NavLink>
                        </Nav>
                        </div>
                    
                    </div>
                </div>
            </div>
        </section>
        <BackToTopBtn/>
        <Footer/>
    </div>
    )
}

export default RJS3
